import React from 'react';
import './ProductGrid.scss';
import { Typography, Button, Box } from '@mui/material';
import smoking from '../../Assets/smoking.jpg'
import phone from '../../Assets/phone.jpg'
import medical from '../../Assets/medical.jpg'
import daycare from '../../Assets/Day-care.jpg'
import gym from '../../Assets/gym.jpg'
import masjid from '../../Assets/masjid.jpg'
import hall from '../../Assets/seminar-hall.jpg'
import gaming from '../../Assets/Services/Games-min.jpg'

const products = [
    { name: 'Masjid', image: masjid, description: 'For your spiritual and prayer needs' },
    { name: 'Designated Smoking Area', image: smoking, description: 'Confortable environment for those who smoke' },
    { name: 'Phone Booths', image: phone, description: 'Quiet sound-proof space' },
    { name: 'Gaming Area', image: gaming, description: 'Variety of games to help you recharge and relax' },
    { name: 'Medical Facilities', image: medical, description: 'Health Care for your peace of mind and well being' },
    { name: 'Day Care', image: daycare, description: 'Ensure your children are cared for in a safe and stimulating environment' },
    { name: 'Gym', image: gym, description: 'Fully Equiped Gym with range of exercise facilities' },

    { name: 'Seminar Hall', image: hall, description: 'Ideally designed for meetings and workshops, with tech facilities' },

];

const ProductGrid = () => {
    return (
        <>
            <div className='flex items-start justify-start px-[10%] mb-16 main-header-outer'>
                <div className='flex items-center justify-between w-full articles-heading'>
                    <Box className='flex flex-col justify-between items-start pricing-content'>
                        <Typography sx={{
                            fontFamily: 'Poppins',
                            fontWeight: '600',
                            fontSize: '15px',
                            color: '#fff',
                            letterSpacing: '4px'
                        }}>
                            SERVICES
                        </Typography>
                        <Typography sx={{
                            fontFamily: 'Poppins',
                            fontWeight: '600',
                            fontSize: '56px',
                            color: '#fff',
                            textAlign: 'left',
                            lineHeight: '1.2em'
                        }} className='main-header'>
                            Our Amenities
                        </Typography>
                    </Box>
                    <Box className='flex flex-col justify-between items-start'>
                        <Button variant="contained"
                            sx={{
                                backgroundColor: '#F02D27',
                                color: '#fff',
                                fontWeight: 'bold',
                                transition: 'opacity 0.6s ease', // Smooth transition for button
                                "&:hover": {
                                    color: "#fff",
                                    background: '#F02D27',
                                },
                            }}>
                            <a href="/Services" className="learn-more">View More →</a>
                        </Button>
                    </Box>
                </div>
            </div>
            <div className="product-grid mx-[10%]">
                {products.map((product, index) => (
                    <div className="product-card" key={index}>
                        <img src={product.image} alt={product.name} className="product-image" />
                        <div className="product-overlay">
                            <Typography sx={{
                                fontFamily: "Poppins",
                                fontSize: '28px',
                                fontWeight: '500',
                                color: '#FFF',
                            }} className='product-heading'>
                                {product.name}
                            </Typography>
                            <Typography sx={{
                                fontFamily: "Poppins",
                                fontSize: '16px',
                                fontWeight: '300',
                                color: '#FFF',
                            }} className='product-description'>
                                {product.description}
                            </Typography>
                        </div>
                    </div>
                ))}
            </div>
        </>
    );
};

export default ProductGrid;
