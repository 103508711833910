import React from 'react'
import Hero from '../../Components/HeroSection/Hero'
import StatisticsSection from '../../Components/Statistics/Stats'
import ProductGrid from '../../Components/ProductGrid/ProductGrid'
import ChaptersComp from '../../Components/Chapters/Chapters'
import Articles from '../../Components/Articles/Articles'
import PricingPlans from '../../Components/Pricing/Pricing'


const Home = () => {
    return (
        <>
            <Hero />
            <StatisticsSection />
            <ProductGrid />
            <ChaptersComp />
            <PricingPlans />
            <Articles />
        </>
    )
}

export default Home
