import React from 'react'
import { Box, Button, MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material'
import './Contact.scss'
import { toast } from 'react-toastify';
import PhoneIcon from '@mui/icons-material/Phone';

const ContactForm = () => {
    const [interestedPackage, setInterestedPackage] = React.useState('');
    const [formData, setFormData] = React.useState({
        firstName: '',
        lastName: '',
        email: '',
        contact: '',
        companyName: '',
        package: '',
        message: '',
    });
    const [errors, setErrors] = React.useState<{ [key: string]: string }>({});

    const handleChange = (event: SelectChangeEvent) => {
        setInterestedPackage(event.target.value as string);
        setFormData({
            ...formData,
            package: event.target.value as string,
        });
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.value,
        });
    };

    const validateForm = (data: typeof formData) => {
        const errors: { [key: string]: string } = {};
        if (!data.firstName) errors.firstName = 'First name is required';
        if (!data.lastName) errors.lastName = 'Last name is required';
        if (!data.email) errors.email = 'Email is required';
        if (!data.contact) errors.contact = 'Contact number is required';
        if (!data.companyName) errors.companyName = 'Company name is required';
        if (!data.package) errors.package = 'Please select a package';
        return errors;
    };

    const handleSubmit = (event: React.FormEvent) => {
        event.preventDefault();
        const validationErrors = validateForm(formData);

        if (Object.keys(validationErrors).length === 0) {
            const config = {
                SecureToken: 'd79e3419-43bb-49db-a8d6-f9cb5d830b6d',
                To: 'waqas@innovistaravi.pk',
                From: 'info@innovistaravi.pk',
                Subject: 'New Contact Form Submission',
                Body: `
                    Name: ${formData.firstName} ${formData.lastName}
                    Email: ${formData.email}
                    Contact: ${formData.contact}
                    Company: ${formData.companyName}
                    Package Interested: ${formData.package}
                    Message: ${formData.message}
                `,
            };

            // Log the config to check the email details
            console.log('Email config:', config);

            window.Email.send(config)
                .then((message: string) => {
                    console.log('Email send response:', message); // Log the response message
                    toast.success('Email sent successfully');
                })
                .catch((error: string) => {
                    console.error('Failed to send email:', error); // Log the error
                    toast.error('Failed to send email. Please try again later.');
                });
        } else {
            setErrors(validationErrors);
        }
    };



    const options = [
        { value: 'Private Offices', label: 'Private Offices' },
        { value: 'Dedicated Offices', label: 'Dedicated Offices' },
        { value: 'Hot Desks', label: 'Hot Desks' },
        { value: 'Meeting Room', label: 'Meeting Room' },
        { value: 'Conference Room', label: 'Conference Room' },
        { value: 'Bespoke Office', label: 'Bespoke Office' },
        { value: 'Huddle Room', label: 'Huddle Room' },
    ];

    return (
        <form action='https://submit-form.com/uA2YJLU4K' method='POST'>
            <Box className='w-[80%] mx-auto my-[15%] rounded-[10px]'>
                <div className='contact-form-main bg-[#2A2A2A] p-[5%] flex items-center gap-14 rounded-[10px]'>
                    <div className='flex flex-col items-start gap-5 w-1/2'>
                        <div className='flex flex-col items-start gap-2'>
                            <Typography sx={{
                                fontFamily: "Poppins",
                                fontSize: '28px',
                                fontWeight: '600',
                                letterSpacing: '-0.5px',
                                color: '#FFF'
                            }}>
                                Get in Touch
                            </Typography>
                            <Typography sx={{
                                fontFamily: "Poppins",
                                fontSize: '18px',
                                fontWeight: '400',
                                letterSpacing: '-0.5px',
                                color: '#FFF'
                            }}>
                                Submit your questions, concerns and inquiries and we will be in touch.
                            </Typography>
                        </div>
                        <div className='flex items-center justify-between w-full gap-10 contact-fields-outer'>
                            <div className='flex flex-col items-start w-full gap-2'>
                                <label htmlFor="firstName">First Name</label>
                                <input
                                    className='input-field'
                                    type='text'
                                    name='firstName'
                                    value={formData.firstName}
                                    onChange={handleInputChange}
                                />
                                {errors.firstName && <span className="error-text">{errors.firstName}</span>}
                            </div>
                            <div className='flex flex-col items-start w-full gap-2'>
                                <label htmlFor="lastName">Last Name</label>
                                <input
                                    className='input-field'
                                    type='text'
                                    name='lastName'
                                    value={formData.lastName}
                                    onChange={handleInputChange}
                                />
                                {errors.lastName && <span className="error-text">{errors.lastName}</span>}
                            </div>
                        </div>

                        <div className='flex items-center justify-between w-full gap-10 contact-fields-outer'>
                            <div className='flex flex-col items-start w-full gap-2'>
                                <label htmlFor="email">Email</label>
                                <input
                                    className='input-field'
                                    type='email'
                                    name='email'
                                    value={formData.email}
                                    onChange={handleInputChange}
                                />
                                {errors.email && <span className="error-text">{errors.email}</span>}
                            </div>
                            <div className='flex flex-col items-start w-full gap-2'>
                                <label htmlFor="contact">Contact No.</label>
                                <input
                                    className='input-field'
                                    type='text'
                                    name='contact'
                                    value={formData.contact}
                                    onChange={handleInputChange}
                                />
                                {errors.contact && <span className="error-text">{errors.contact}</span>}
                            </div>
                        </div>
                        <div className='flex items-center justify-between w-full gap-10 contact-fields-outer'>
                            <div className='flex flex-col items-start w-full gap-2'>
                                <label htmlFor="companyName">Company Name</label>
                                <input
                                    className='input-field'
                                    type='text'
                                    name='companyName'
                                    value={formData.companyName}
                                    onChange={handleInputChange}
                                />
                                {errors.companyName && <span className="error-text">{errors.companyName}</span>}
                            </div>
                            <div className='flex flex-col items-start w-full gap-2'>
                                <label htmlFor="package">Package Interested</label>
                                <Select
                                    labelId="interested-package"
                                    value={interestedPackage}
                                    name='interestedPackage'
                                    onChange={handleChange}
                                    className='w-full'
                                    sx={{
                                        border: '1px solid #8C8C8C',
                                        borderRadius: '10px',
                                        color: '#fff',
                                        fontFamily: 'Poppins'
                                    }}
                                >
                                    {options.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {errors.package && <span className="error-text">{errors.package}</span>}
                            </div>
                        </div>
                        <div className='flex flex-col items-start w-full gap-2'>
                            <label htmlFor="message">Message</label>
                            <input
                                className='input-field'
                                type='text'
                                name='message'
                                value={formData.message}
                                onChange={handleInputChange}
                            />
                            {errors.message && <span className="error-text">{errors.message}</span>}
                        </div>
                        <Button
                            variant="contained"
                            type="submit"
                            sx={{
                                backgroundColor: '#F02D27',
                                color: '#fff',
                                fontWeight: 'bold',
                                transition: 'opacity 0.6s ease',
                                "&:hover": {
                                    color: "#fff",
                                    background: '#F02D27',
                                },
                            }}
                        >
                            Submit
                        </Button>
                    </div>

                    <div className='w-1/2 flex flex-col gap-4'>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13607.142183573036!2d74.4257014!3d31.502578!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39190f001a9d06fd%3A0xb8b4c5a1ce3195e7!2sInnovista%20Technology%20Zone%20Ravi!5e0!3m2!1sen!2s!4v1730123960311!5m2!1sen!2s" width="100%" height="600px" loading="lazy" ></iframe>

                        <div className='text-white flex items-center justify-center gap-4'>

                            <div className='number-div flex items-center gap-2'>
                                <PhoneIcon className='number-icon' />
                                <Typography className='num-text'>
                                    +92 313 3337284
                                </Typography>
                            </div>
                            <div className='number-div flex items-center gap-2'>
                                <PhoneIcon className='number-icon' />
                                <Typography className='num-text'>
                                    +92 310 8147284
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>
            </Box>
        </form>
    );
}

export default ContactForm;
