// ChatIcon.js
import React from 'react';
import { Link } from 'react-router-dom';
import ChatBubbleIcon from '@mui/icons-material/ChatBubble';
import './ChatIcon.scss';

const ChatIcon = () => (
    <Link to="/Contact" className="chat-icon">
        <ChatBubbleIcon style={{ fontSize: 28, color: 'white' }} />
    </Link>
);

export default ChatIcon;
