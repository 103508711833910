import React, { useState, useEffect } from 'react';
import './Hero.scss';
import backgroundVideo from '../../Assets/BGvideo.mp4';
import { Button, Typography } from '@mui/material';
import EastIcon from '@mui/icons-material/East';

const Hero = () => {
    const [videoWidth, setVideoWidth] = useState('100%');
    const [showFullVideo, setShowFullVideo] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            if (scrollTop > window.innerHeight * 0.3) {
                setVideoWidth('70%');
                setShowFullVideo(false);
            } else {
                setVideoWidth('100%');
                setShowFullVideo(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div
                className={`relative flex flex-col items-center justify-center transition-all duration-500`}
                style={{ width: videoWidth, margin: '0 auto' }}
            >
                <video src={backgroundVideo} muted autoPlay loop className='absolute z-[-1] rounded-[10px] hero-video bg-image' />
                <div className='flex flex-col items-center justify-center p-[15%] gap-5 hero-text'>
                    <Typography sx={{
                        fontFamily: "Poppins",
                        fontSize: '28px',
                        fontWeight: '400',
                        letterSpacing: '-0.5px',
                        color: '#FFF'
                    }} className='hero-subheading'>
                        A coworking space all across Pakistan
                    </Typography>
                    <Typography
                        sx={{
                            fontFamily: "Poppins",
                            fontSize: '120px',
                            fontWeight: '700',
                            textTransform: 'uppercase',
                            lineHeight: '110px',
                            letterSpacing: '-0.5px',
                            color: '#FFF',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            // borderRight: '4px solid',
                            animation: 'blink-caret 0.75s step-end infinite',
                        }}
                        className="innovation-typing"
                    >
                        <span className="typing-text"></span>
                    </Typography>
                </div>
            </div>

            <div className='flex flex-col items-center justify-center relative home-about-main' style={{
                background: 'linear-gradient(220deg, #EA406F 19%, #FD5D47 85%)',
                color: '#FFF',
                padding: '50px',
                borderRadius: '10px',
                maxWidth: '1200px',
                margin: '50px auto',
                textAlign: 'left',

            }}>
                <div className='flex gap-20 items-center home-about-inner'>
                    <div className='w-1/2 home-about-inner-left'>
                        <Typography sx={{
                            fontFamily: "Poppins",
                            fontSize: '14px',
                            fontWeight: '600',
                            letterSpacing: '1px',
                            textTransform: 'uppercase',
                            marginBottom: '10px',
                            color: '#000',

                        }}>
                            About Innovista
                        </Typography>
                        <Typography sx={{
                            fontFamily: "Poppins",
                            fontSize: '36px',
                            fontWeight: '700',
                            lineHeight: '1.2',
                            marginBottom: '20px',
                        }}>
                            Your Creative Business Hub
                        </Typography>
                    </div>
                    <div className='w-1/2 home-about-inner-right'>
                        <Typography sx={{
                            fontFamily: "Poppins",
                            fontSize: '16px',
                            fontWeight: '400',
                            lineHeight: '1.8',
                        }}>
                            Innovista Ravi is more than just a coworking space—it's a hub for innovation located in Lahore’s business heart, DHA Phase 8. Designed to meet international standards, our space caters to startups, freelancers, and established companies, providing them with the resources they need to succeed in a collaborative environment.
                        </Typography>
                    </div>
                </div>
                <Button sx={{
                    background: '#fff',
                    color: '#FF6041',
                    padding: '20px',
                    borderRadius: '100%',
                    maxWidth: '1200px',
                    textAlign: 'left',
                    position: 'absolute',
                    bottom: '-12%',
                    boxShadow: '0px 0px 11px 2px rgba(0, 0, 0, 0.15)',
                    "&:hover": {
                        background: '#fff',
                    },
                }} className='arrow-div'>
                    <a href="/About" target='_BLANK'><EastIcon /></a>
                </Button>
            </div>
        </>
    );
};

export default Hero;
