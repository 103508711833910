import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './ComingSoon.scss';

const ComingSoon = () => {
    const navigate = useNavigate();

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/');
        }, 3000);

        return () => clearTimeout(timer); // Cleanup the timeout if the component unmounts
    }, [navigate]);

    return (
        <div className="comingSoon-main flex flex-col items-start justify-start">
            <Box
                sx={{
                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '2rem',
                    padding: '5% 5% 5% 10%',
                }}
            >
                <div>
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: '64px',
                            lineHeight: '1.25em',
                            fontWeight: '700',
                            color: '#fff',
                        }}
                    >
                        Thank You!{' '}
                    </Typography>
                </div>
                <div>
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: '48px',
                            fontWeight: '500',
                            color: '#fff',
                        }}
                    >
                        Your Form Has Been Submitted.{' '}
                    </Typography>
                </div>
                <div>
                    <Typography
                        sx={{
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            lineHeight: '1.25em',
                            fontWeight: '400',
                            color: '#fff',
                        }}
                    >
                        Redirecting to home page  <span className="dots">
                            <span>.</span>
                            <span>.</span>
                            <span>.</span>
                        </span>
                    </Typography>
                </div>
            </Box>
        </div>
    );
};

export default ComingSoon;
