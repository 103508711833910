import React, { useState, useEffect } from 'react';
import { Typography } from '@mui/material';

// Define the interface for the CountUp component props
interface CountUpProps {
    end: number;
    suffix?: string; // Optional suffix to add after counting completes
}

// The CountUp component that handles the counting animation
const CountUp: React.FC<CountUpProps> = ({ end, suffix = '' }) => {
    const [count, setCount] = useState(0);

    useEffect(() => {
        let start = 0;
        const duration = 4000; // Duration of the count animation in milliseconds
        const incrementTime = Math.abs(Math.floor(duration / end));

        const timer = setInterval(() => {
            start += 1;
            setCount((prevCount) => {
                if (prevCount >= end) {
                    clearInterval(timer);
                    return end;
                }
                return start;
            });
        }, incrementTime);

        return () => clearInterval(timer);
    }, [end]);

    return (
        <Typography sx={{
            fontFamily: "Poppins",
            fontSize: '40px',
            fontWeight: '700',
        }} className='count'>
            {count.toLocaleString()} {count >= end ? suffix : ''}
        </Typography>
    );
};

export default CountUp;
