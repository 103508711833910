import React from 'react';
import { Typography } from '@mui/material';
import CountUp from './CountUp';
import './Stats.scss';

const StatisticsSection: React.FC = () => {
    const stats = [
        { label: 'Total square footage', value: '644,527', isCountUp: false },
        { label: 'Total IT companies', value: 25, suffix: '+', isCountUp: true },
        { label: 'Years of Operation', value: 5, suffix: '+', isCountUp: true },
        { label: 'Member satisfaction rate', value: 100, suffix: '%', isCountUp: true },
        { label: 'Hours of Operation', value: '24/7', isCountUp: false },
    ];

    return (
        <div style={{
            color: '#fff',
            textAlign: 'center',
            margin: 'auto'
        }} className='stats-main'>
            {stats.map((stat, index) => (
                <div key={index}>
                    <Typography sx={{
                        fontFamily: "Poppins",
                        fontSize: '18px',
                        fontWeight: '400',
                        marginBottom: '10px',
                    }} className='count-heading'>
                        {stat.label}
                    </Typography>
                    {stat.isCountUp ? (
                        <CountUp end={stat.value as number} suffix={stat.suffix} />
                    ) : (
                        <Typography sx={{
                            fontFamily: "Poppins",
                            fontSize: '40px',
                            fontWeight: '700',
                        }} className='count'>
                            {stat.value}
                        </Typography>
                    )}
                </div>
            ))}
        </div>
    );
};

export default StatisticsSection;
