import React from 'react';
import './Chapters.scss';
import { Typography } from '@mui/material';
import Rawalpindi from '../../Assets/CitiesChapters/rawalpindi.png'
import Lahore from '../../Assets/CitiesChapters/lahore.png'
import Quetta from '../../Assets/CitiesChapters/queta.png'
import Bahawalpur from '../../Assets/CitiesChapters/Bahawalpur.png'
import Gujranwala from '../../Assets/CitiesChapters/Gujranwala-.png'
import Karachi from '../../Assets/CitiesChapters/karachi.png'
import Mehran from '../../Assets/CitiesChapters/mehran.png'
import Multan from '../../Assets/CitiesChapters/multan.png'
import Peshawar from '../../Assets/CitiesChapters/peshawar.png'
import Rawat from '../../Assets/CitiesChapters/rawat.png'
import logo from '../../Assets/InnovistaRaviLogoWhite.png'
import { useNavigate } from 'react-router-dom';

const services = [
    { id: '01', name: 'Bahawalpur', image: Bahawalpur, city: 'Innovista Cholistan' },
    { id: '02', name: 'Gujranwala', image: Gujranwala, city: 'Innovista Rachna' },
    { id: '03', name: 'Karachi', image: Karachi, city: 'Innovista Indus' },
    { id: '04', name: 'Lahore', image: Lahore, city: 'Innovista Ravi' },
    { id: '05', name: 'Mehran', image: Mehran, city: 'Innovista Mehran' },
];

const servicesRight = [
    { id: '06', name: 'Multan', image: Multan, city: 'Innovista Chenab' },
    { id: '07', name: 'Peshawar', image: Peshawar, city: 'Innovista Khyber' },
    { id: '08', name: 'Quetta', image: Quetta, city: 'Innovista Chaghi' },
    { id: '09', name: 'Rawalpindi', image: Rawalpindi, city: 'Innovista Rawal' },
    { id: '10', name: 'Rawat', image: Rawat, city: 'Innovista Rawat' },
];


const servicesMob = [
    { id: '01', name: 'Bahawalpur', image: Bahawalpur, city: 'Innovista Cholistan' },
    { id: '02', name: 'Gujranwala', image: Gujranwala, city: 'Innovista Rachna' },
    { id: '03', name: 'Karachi', image: Karachi, city: 'Innovista Indus' },
    { id: '04', name: 'Lahore', image: Lahore, city: 'Innovista Ravi' },
    { id: '05', name: 'Mehran', image: Mehran, city: 'Innovista Mehran' },
    { id: '06', name: 'Multan', image: Multan, city: 'Innovista Chenab' },
    { id: '07', name: 'Peshawar', image: Peshawar, city: 'Innovista Khyber' },
    { id: '08', name: 'Quetta', image: Quetta, city: 'Innovista Chaghi' },
    { id: '09', name: 'Rawalpindi', image: Rawalpindi, city: 'Innovista Rawal' },
    { id: '10', name: 'Rawat', image: Rawat, city: 'Innovista Rawat' },
];

const ChaptersComp = () => {

    const navigate = useNavigate();

    const handleClick = (id: string) => {
        window.open(`/chapter/${id}`, '_blank');
    };

    return (
        <>
            <div className="explore-observatory grid-cols-3">
                <div className="header">
                    <div>
                        <Typography variant="h6" className="sub-header">OUR CHAPTERS</Typography>
                        <Typography variant="h2" className="main-header">Explore the Chapters</Typography>
                    </div>
                </div>

                <div className='chapters-circle w-full'>
                    <div className="service-grid w-[30%]">
                        {services.map((service, index) => {
                            let marginClass = '';
                            if (index === 0) marginClass = 'ml-[50%]';
                            if (index === 1) marginClass = 'ml-[25%]';
                            if (index === 3) marginClass = 'ml-[25%]';
                            if (index === 4) marginClass = 'ml-[50%]';

                            return (
                                <div className={`service-card justify-end ${marginClass}`} key={index} onClick={() => handleClick(service.id)}>
                                    <div>
                                        <Typography sx={{
                                            fontFamily: "Poppins",
                                            fontSize: '28px',
                                            fontWeight: '600',
                                            letterSpacing: '-0.5px',
                                            color: '#FFF'
                                        }} className='name-heading'>
                                            {service.name}
                                        </Typography>
                                        <Typography sx={{
                                            fontFamily: "Poppins",
                                            fontSize: '18px',
                                            fontWeight: '400',
                                            letterSpacing: '-0.5px',
                                            color: '#FFF'
                                        }} className='name-subheading'>
                                            {service.city}
                                        </Typography>
                                    </div>
                                    <img src={service.image} alt={service.name} className="service-image" />
                                </div>
                            );
                        })}
                    </div>
                    <div className='flex items-center justify-center w-[40%] mx-[5%] chapter-logo'>
                        <img src={logo} alt="" className='w-[80%]' />
                    </div>
                    <div className="service-grid w-[30%]">
                        {servicesRight.map((service, index) => {
                            let marginClass = '';
                            if (index === 0) marginClass = 'ml-[-50%]';
                            if (index === 1) marginClass = 'ml-[-25%]';
                            if (index === 3) marginClass = 'ml-[-25%]';
                            if (index === 4) marginClass = 'ml-[-50%]';

                            return (
                                <div className={`service-card ${marginClass}`} key={index} onClick={() => handleClick(service.id)}>
                                    <img src={service.image} alt={service.name} className="service-image" />
                                    <div>
                                        <Typography sx={{
                                            fontFamily: "Poppins",
                                            fontSize: '28px',
                                            fontWeight: '600',
                                            letterSpacing: '-0.5px',
                                            color: '#FFF'
                                        }}>
                                            {service.name}
                                        </Typography>
                                        <Typography sx={{
                                            fontFamily: "Poppins",
                                            fontSize: '18px',
                                            fontWeight: '400',
                                            letterSpacing: '-0.5px',
                                            color: '#FFF'
                                        }}>
                                            {service.city}
                                        </Typography>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="service-grid-mob w-full">
                        {servicesMob.map((service, index) => {
                            return (
                                <div className='service-card' key={index} onClick={() => handleClick(service.id)}>
                                    <img src={service.image} alt={service.name} className="service-image" />
                                    <div className='flex flex-col items-center'>
                                        <Typography sx={{
                                            fontFamily: "Poppins",
                                            fontSize: '28px',
                                            fontWeight: '600',
                                            letterSpacing: '-0.5px',
                                            color: '#FFF'
                                        }} className='city-heading'>
                                            {service.name}
                                        </Typography>
                                        <Typography sx={{
                                            fontFamily: "Poppins",
                                            fontSize: '18px',
                                            fontWeight: '400',
                                            letterSpacing: '-0.5px',
                                            color: '#FFF'
                                        }} className='city-subheading'>
                                            {service.city}
                                        </Typography>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div >
            <div className='textAnimation py-[5%]'>
                <Typography className="scrolling-text" sx={{
                    fontWeight: '400',
                    color: '#fff',
                    fontSize: '78px',
                    whiteSpace: 'nowrap' // Ensure the text doesn't wrap
                }}>
                    ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ● ELEVATE YOUR WORK EXPERIENCE ●
                </Typography>
            </div>

        </>
    );
};

export default ChaptersComp; 
