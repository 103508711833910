import React from 'react';
import './index.css';
import ComingSoon from './Components/ComingSoon/ComingSoon';
import { Route, Routes, useLocation } from 'react-router-dom';
import NavBar from './Components/Navbar/Navbar';
import Home from './Pages/Home/Home';
import About from './Pages/About/About';
import Contact from './Pages/Contact/Contact';
import Blogs from './Pages/Blogs/Blogs';
import Footer from './Components/Footer/Footer';
import ChapterDetail from './Components/Chapters/ChapterDetail';
import Service from './Pages/Services/Service';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ArticleSingle from './Components/Articles/ArticleSingle';
import ChatIcon from './Components/ChatIcon/ChatIcon';

const App = () => {
    const location = useLocation();

    const hideNavAndFooter = location.pathname === '/Thankyou';
    const hideChat = location.pathname === '/Contact'

    return (
        <>
            {!hideNavAndFooter && <NavBar />}
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/About' element={<About />} />
                <Route path='/Contact' element={<Contact />} />
                <Route path='/Blogs' element={<Blogs />} />
                <Route path='/Services' element={<Service />} />
                <Route path='/Thankyou' element={<ComingSoon />} />
                <Route path='/chapter/:id' element={<ChapterDetail />} />
                <Route path='/article/:id' element={<ArticleSingle />} />
            </Routes>
            {!hideNavAndFooter && <Footer />}
            {!hideChat && <ChatIcon />}
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                closeOnClick
                pauseOnHover
            />
        </>
    );
};

export default App;
