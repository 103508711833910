import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import backgroundAbout from '../../Assets/bg-grad-min.png';
import aboutHeader from '../../Assets/InnovistaContent2-min.png'
import aboutHeaderVision from '../../Assets/InnovistaContent5-min.png'
import igi from '../../Assets/IGI 1.png'
import ignite from '../../Assets/Ignite.png'
import pasha from '../../Assets/pasha.png'
import devsync from '../../Assets/Devsinc.png'
import netsol from '../../Assets/NetSol.png'
import './About.scss'

const About = () => {

    const [videoWidth, setVideoWidth] = useState('100%');
    const [showFullVideo, setShowFullVideo] = useState(true);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;

            if (scrollTop > window.innerHeight * 0.3) {
                setVideoWidth('70%');
                setShowFullVideo(false);
            } else {
                setVideoWidth('100%');
                setShowFullVideo(true);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const images = [
        {
            src: igi
        },
        {
            src: pasha
        },
        {
            src: devsync
        },
        {
            src: ignite
        },
        {
            src: netsol
        },
    ]
    return (
        <>
            <div
                className={`relative flex flex-col items-center justify-center transition-all duration-500`}
                style={{ width: videoWidth, margin: '0 auto' }}
            >
                <img src={backgroundAbout} className='absolute z-[-1] rounded-[10px]' />
                <div className='flex flex-col items-center justify-center p-[20%] gap-5'>
                    <Typography className='heading-text' sx={{
                        fontFamily: "Poppins",
                        fontSize: '120px',
                        fontWeight: '700',
                        textTransform: 'uppercase',
                        lineHeight: '105px',
                        letterSpacing: '-5px',
                        color: '#FFF',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}>
                        ABOUT US
                    </Typography>
                </div>
            </div>

            <div className='services-header px-[10%] mt-[15%] mb-[2%] flex flex-col items-center justify-center gap-2 w-full'>
                <Typography variant="h3" className="title-about">
                    About Us
                </Typography>
                <Typography variant="body1" className="description-about">
                    Innovista Ravi is more than just a coworking space—it's a hub for innovation located in Lahore’s business heart, DHA Phase 8. Designed to meet international standards, our space caters to startups, freelancers, and established companies, providing them with the resources they need to succeed in a collaborative environment.
                </Typography>
            </div>
            <div className='services-header px-[10%] mt-[5%] mb-[5%] flex items-center gap-10'>
                <div className='flex flex-col items-start gap-2'>
                    <Typography variant="h3" className="title-about">
                        Our Mission
                    </Typography>
                    <Typography variant="body1" className="description">
                        At Innovista, our mission is to create a dynamic and inclusive ecosystem of co working spaces that fosters innovation, empowers entrepreneurs, and drives economic growth across Pakistan. We are dedicated to providing world-class facilities, comprehensive support services, and strategic partnerships to nurture a thriving community of startups,freelancers, and technology companies. Through our commitment to diversity, inclusivity, and equal opportunities, we aim to empower women entrepreneurs, youth, and small to mid-size enterprises (SMEs) to succeed in the digital economy.
                    </Typography>
                </div>
                <div className='w-1/2 header-image'>
                    <img src={aboutHeader} alt="" className='rounded-[10px]' />
                </div>

            </div>
            <div className='services-header-vision px-[10%] mt-[5%] mb-[5%] flex items-center gap-10'>
                <div className='w-1/2 header-image'>
                    <img src={aboutHeaderVision} alt="" className='rounded-[10px]' />
                </div>
                <div className='flex flex-col items-start gap-2'>
                    <Typography variant="h3" className="title-about">
                        Our Vision
                    </Typography>
                    <Typography variant="body1" className="description">
                        Our vision at Innovista is to be the leading catalyst for digital transformation and innovation in Pakistan. We aspire to establish Innovista co-working spaces in all major cities and second-tier cities across the country,providing accessible and affordable work spaces equipped with state-of-the-art amenities and facilities. By fostering collaboration, knowledge exchange, and international partnerships, we aim to create a vibrant ecosystem that drives entrepreneurship, empowers the youth, and accelerates the growth of the technology sector in Pakistan.
                    </Typography>
                </div>
            </div>
            {/* <div className='flex flex-col items-center justify-center gap-5'>
                <Typography variant="h3" className="title-about">
                    Our Partners
                </Typography>
                <div className='about-logos grid grid-cols-5 px-[10%] gap-5'>
                    {images.map((img, index) => (
                        <div className='bg-[#212121] p-6 rounded-[10px]'>
                            <img src={img.src} alt="" key={index} />
                        </div>
                    ))}
                </div>
            </div> */}

        </>
    )
}

export default About
