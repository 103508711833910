import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import backgroundAbout from '../../Assets/InnovistaContent6-min.png';
import servicePageHeader from '../../Assets/InnovistaContent5-min.png';
import gaming from '../../Assets/Services/Games-min.jpg';
import conference from '../../Assets/Services/ConferenceHall-min.jpg';
import kitchen from '../../Assets/Services/Kitchen-min.jpg';
import privateOffice from '../../Assets/Services/Privateoffice-min.jpg';
import dedicatedSpaces from '../../Assets/Services/Teamspaces-min.jpg';
import innovationLabs from '../../Assets/Services/Innovationlabs-min.jpg';
import experts from '../../Assets/Services/ExpertMentors-min.jpg';
import conferenceHalls from '../../Assets/Services/halls-min.jpg';
import stage from '../../Assets/Services/Seminarhall-min.jpg';
import productivity from '../../Assets/Services/CarParking-min.jpg';
import wifiFacility from '../../Assets/Services/5g-min.jpg';
import bigDay from '../../Assets/Services/OnedayPass-min.jpg';
import './Service.scss';
import PricingPlans from '../../Components/Pricing/Pricing';

const Service = () => {
    const [videoWidth, setVideoWidth] = useState('100%');
    const [showFullVideo, setShowFullVideo] = useState(true);


    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > window.innerHeight * 0.3) {
                setVideoWidth('70%');
                setShowFullVideo(false);
            } else {
                setVideoWidth('100%');
                setShowFullVideo(true);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const services = [
        {
            image: conference,
            heading: 'Coworking Space for Co Creation',
            description: 'Our coworking spaces at Innovista Ravi are more than just desks—they’re a launchpad for your ideas. With flexible membership options, you can choose a plan that fits your schedule and budget. Surrounded by like-minded professionals, you’ll experience the energy of a collaborative environment that fosters creativity and growth. Enjoy top-tier amenities that’s always ready to inspire and support your journey.',
        },
        {
            image: privateOffice,
            heading: 'Productivity with Private Offices',
            description: 'Private offices at Innovista Ravi are tailored for those who value discretion and focus, our private offices provide all the amenities you need to operate seamlessly. From high-speed internet to ergonomic workstations, every detail is designed to enhance your productivity. Enjoy the benefits of a quiet space that’s all yours, while still being part of a vibrant community that offers networking opportunities and business support.',
        },
        {
            image: dedicatedSpaces,
            heading: 'Dedicated Teamspace for Decisive Impact',
            description: 'Innovista Ravi’s Dedicated Teamspaces provide your team with the focus and functionality required to achieve your goals, featuring high-speed internet, ergonomic workstations, and a range of top-tier amenities. Whether you’re brainstorming new ideas or executing key projects, our dedicated teamspaces offer the perfect balance of quiet concentration and collective synergy.',
        },
        {
            image: innovationLabs,
            heading: 'Innovation Labs to develop Prototypes and Launch Solutions',
            description: 'Our Innovation Labs are the ideal environment for startups and innovators to bring their boldest ideas to life. Equipped with cutting-edge technology and advanced tools, these labs offer everything you need to experiment, create, and refine your projects. Whether you’re developing a new product, testing concepts, our Innovation Labs provide the resources and support to help you push the boundaries of what’s possible.',
        },
        {
            image: experts,
            heading: 'Expert Mentors for Your Entrepreneurial Journey',
            description: 'Innovista Ravi’s Mentorship Programs are your gateway to expert advice and strategic insights, tailored specifically for your entrepreneurial journey. Our programs connect you with seasoned mentors who understand the complexities of building a business and are committed to helping you succeed. From refining your business model to overcoming obstacles, our mentors provide the guidance you need to make informed decisions and accelerate your growth.',
        },
        {
            image: stage,
            heading: 'Your Stage for Success',
            description: "Our state-of-the-art Seminar Hall is designed to empower your presentations and elevate your events. Whether you're hosting a corporate training session, a product launch, or a conference, our space provides the perfect backdrop for your success. Equipped with advanced audio-visual technology and flexible seating arrangements, the Seminar Hall ensures a seamless experience for both you and your audience.",
        },
        {
            image: conferenceHalls,
            heading: 'Your Collaborative Cornerstone',
            description: "Our Conference Rooms are designed to foster innovation and productivity. Whether you're brainstorming ideas, conducting client meetings, or hosting team workshops, these spaces offer the perfect environment for collaboration. Equipped with state-of-the-art technology and comfortable seating, our Conference Rooms ensure that every meeting is a success.",
        },
        {
            image: productivity,
            heading: 'Uninterrupted Productivity',
            description: "At Innovista Ravi, we understand that great ideas can strike at any hour. That’s why we offer 24/7 office access to our members. Enjoy the freedom to work on your terms, without constraints. Whether you're a night owl or an early bird, our spaces are always open to accommodate your productivity rhythm.",
        }, {
            image: kitchen,
            heading: 'Fuel Your Focus',
            description: "A well-nourished mind is a productive mind. Our cafeteria offers a delicious range of culinary delights to keep you energized throughout the day. From healthy salads to satisfying main courses, our menu caters to diverse tastes. Enjoy a refreshing break or a power lunch in a comfortable and inviting atmosphere.",
        }, {
            image: wifiFacility,
            heading: '5G Wifi Connectivity - Speed and Reliability',
            description: "Stay connected and ahead with our blazing-fast 5G Wi-Fi. Experience seamless browsing, lightning-fast downloads, and uninterrupted video conferencing. Our high-speed internet ensures you have the tools you need to succeed.",
        },
        {
            image: gaming,
            heading: 'Recharge and Reimagine',
            description: "Take a break from work and recharge in the Game Zone. This vibrant space offers a refreshing escape where you can relax, socialize, and re-energize. Whether it's a game of foosball, a friendly chat, or simply enjoying a moment of quiet, our Fun Area is here for rejuvenation.",
        },
        {
            image: bigDay,
            heading: 'Day Pass: Plan Your Big Day',
            description: "Need a productive space for a day? Our Day Pass offers instant access to our coworking community. Enjoy a dedicated workspace, high-speed internet, and access to our shared amenities. Perfect for remote workers, freelancers, or anyone seeking a change of scenery. Experience the Innovista Ravi difference with a Day Pass",
        },

    ]

    return (
        <>
            <div
                className={`relative flex flex-col items-center justify-center transition-all duration-500`}
                style={{ width: videoWidth, margin: '0 auto' }}
            >
                <img src={backgroundAbout} className="absolute z-[-1] rounded-[10px] bg-image" />
                <div className="flex flex-col items-center justify-center p-[20%] gap-5">
                    <Typography className="heading-text" sx={{
                        fontFamily: "Poppins",
                        fontSize: '120px',
                        fontWeight: '700',
                        textTransform: 'uppercase',
                        lineHeight: '105px',
                        letterSpacing: '-5px',
                        color: '#FFF',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                    }}>
                        Services
                    </Typography>
                </div>
            </div>

            <div className="services-header px-[10%] mb-[5%] flex items-center gap-10" style={{
                background: 'linear-gradient(220deg, #EA406F 19%, #FD5D47 85%)',
                padding: '50px',
                borderRadius: '10px',
                maxWidth: '1200px',
                margin: '50px auto',
                textAlign: 'left',
            }}>
                <div className="w-1/2 header-image">
                    <img src={servicePageHeader} alt="" className="rounded-[10px]" />
                </div>
                <div className="flex flex-col items-start gap-5">
                    <Typography variant="h6" className="text-white" sx={{ fontFamily: 'Poppins' }}>Service Package</Typography>
                    <Typography variant="h3" className="title">
                        Your Potential, Innovista Resources
                    </Typography>
                    <Typography variant="body1" className="description">
                        At Innovista Ravi, your success is our priority. That’s why we’ve brought together everything you need under one roof, ensuring that resources never limit your potential.
                    </Typography>
                </div>
            </div>

            <PricingPlans />

            <Box className="px-[10%] py-[5%] flex flex-col items-start gap-[4rem] services-grid-main">
                <div className="flex items-center justify-between w-full articles-heading">
                    <Box className="flex flex-col justify-between items-start">
                        <Typography sx={{
                            fontFamily: 'Poppins',
                            fontWeight: '600',
                            fontSize: '56px',
                            color: '#fff',
                            lineHeight: '1.2em'
                        }} className="main-header">
                            Services
                        </Typography>
                    </Box>
                </div>
                <Box className="grid grid-cols-3 gap-4 articles-grid">
                    {services.map((data, index) => (
                        <div className="flex flex-col items-start justify-start gap-5 bg-[#2A2A2A] rounded-[10px] services-cards" key={index}>
                            <Box className="relative">
                                <img src={data.image} alt="" className="rounded-t-[10px] h-[340px]" loading="lazy" />
                            </Box>
                            <div className="p-5 flex flex-col items-start gap-3">
                                <Typography sx={{
                                    fontFamily: 'Poppins',
                                    fontWeight: '600',
                                    fontSize: '24px',
                                    color: '#fff',
                                }}>
                                    {data.heading}
                                </Typography>
                                <Typography sx={{
                                    fontFamily: 'Poppins',
                                    fontWeight: '400',
                                    fontSize: '14px',
                                    color: '#fff',
                                    lineHeight: '1.5em'
                                }}>
                                    {data.description}
                                </Typography>
                            </div>
                        </div>
                    ))}
                </Box>
            </Box>


        </>
    );
};

export default Service;
