import * as React from 'react';
import { useState, useEffect } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';
import logo from '../../Assets/InnovistaRaviLogoWhite.png';
import logoBlack from '../../Assets/logoBlack.png';
import './Navbar.scss';

const pages = [
  { name: 'Home', path: '/' },
  { name: 'About us', path: '/About' },
  { name: 'Services', path: '/Services' },
  { name: 'Learn Easy', path: 'https://learneasy.pk/', external: true },
  { name: 'Talent Pool', path: 'https://innotalent.pk/', external: true },
  { name: 'Blog', path: '/Blogs' },
];

function NavBar() {
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const [anchorElService, setAnchorElService] = useState<null | HTMLElement>(null);
  const [anchorElPage, setAnchorElPage] = useState<null | HTMLElement>(null);
  const [scrolled, setScrolled] = useState(false);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 400) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenServiceMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElService(event.currentTarget);
  };

  const handleOpenPageMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElPage(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseServiceMenu = () => {
    setAnchorElService(null);
  };

  const handleClosePageMenu = () => {
    setAnchorElPage(null);
  };

  return (
    <AppBar
      position="fixed"
      className={`navbar-main ${scrolled ? 'scrolled' : ''}`}
      sx={{
        backgroundColor: scrolled ? '#fff' : 'transparent',
        color: scrolled ? '#000' : '#fff',
        padding: '0.5% 10%',
        boxShadow: scrolled ? '0px 4px 10px rgba(0, 0, 0, 0.1)' : 'none',
        transition: 'all 0.3s ease-in-out',
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Toolbar>
        <Typography
          variant="h6"
          noWrap
          component="a"
          href="/"
          sx={{
            mr: 2,
            display: { xs: 'none', md: 'flex' },
            fontWeight: 700,
            color: 'inherit',
            textDecoration: 'none',
            width: '9%',
            opacity: scrolled && !hovered ? 0 : 1, // Hide when scrolled and not hovered
            transition: 'opacity 0.6s ease', // Smooth transition for logo
          }}
        >
          <img src={scrolled ? logoBlack : logo} alt="Innovista Logo" />
        </Typography>

        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="menu"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {pages.map((page) => (
              <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                <Typography textAlign="center">
                  {page.external ? (
                    <a href={page.path} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                      {page.name}
                    </a>
                  ) : (
                    <Link to={page.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                      {page.name}
                    </Link>
                  )}
                </Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>

        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center' }}>
          {!hovered && scrolled ? (
            <div className="menu-bar"></div>
          ) : (
            pages.map((page) => (
              <Button
                key={page.name}
                onClick={handleCloseNavMenu}
                sx={{ my: 2, color: 'inherit', display: 'block', mx: 1.5 }} // Adjusted to inherit color for transitions
              >
                {page.name === 'Service' ? (
                  <div onClick={handleOpenServiceMenu} style={{ display: 'flex', alignItems: 'center' }}>
                    Services <ArrowDropDownIcon />
                  </div>
                ) : page.name === 'Page' ? (
                  <div onClick={handleOpenPageMenu} style={{ display: 'flex', alignItems: 'center' }}>
                    Page <ArrowDropDownIcon />
                  </div>
                ) : page.external ? (
                  <a href={page.path} target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'inherit' }}>
                    {page.name}
                  </a>
                ) : (
                  <Link to={page.path} style={{ textDecoration: 'none', color: 'inherit' }}>
                    {page.name}
                  </Link>
                )}
              </Button>
            ))
          )}
        </Box>
        <Button
          variant="contained"
          sx={{
            backgroundColor: '#F02D27',
            color: '#fff',
            fontWeight: 'bold',
            opacity: scrolled && !hovered ? 0 : 1, // Hide when scrolled and not hovered
            transition: 'opacity 0.6s ease', // Smooth transition for button
            "&:hover": {
              color: "#fff",
              background: '#F02D27',
            },
          }}
        >
          <a href="/Contact" style={{ textDecoration: 'none', color: 'inherit' }}>Contact Us</a>
        </Button>
      </Toolbar>

      {/* Service Menu */}
      <Menu
        id="service-menu"
        anchorEl={anchorElService}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElService)}
        onClose={handleCloseServiceMenu}
      >
        <MenuItem onClick={handleCloseServiceMenu}>
          <Link to="/services/service1" style={{ textDecoration: 'none', color: 'inherit' }}>
            Service 1
          </Link>
        </MenuItem>
        <MenuItem onClick={handleCloseServiceMenu}>
          <Link to="/services/service2" style={{ textDecoration: 'none', color: 'inherit' }}>
            Service 2
          </Link>
        </MenuItem>
      </Menu>

      {/* Page Menu */}
      <Menu
        id="page-menu"
        anchorEl={anchorElPage}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        open={Boolean(anchorElPage)}
        onClose={handleClosePageMenu}
      >
        <MenuItem onClick={handleClosePageMenu}>
          <Link to="/page/page1" style={{ textDecoration: 'none', color: 'inherit' }}>
            Page 1
          </Link>
        </MenuItem>
        <MenuItem onClick={handleClosePageMenu}>
          <Link to="/page/page2" style={{ textDecoration: 'none', color: 'inherit' }}>
            Page 2
          </Link>
        </MenuItem>
      </Menu>
    </AppBar>
  );
}

export default NavBar;
