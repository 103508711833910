import React, { useState } from 'react';
import Slider from 'react-slick';
import './Slider.scss';

interface SliderMainProps {
    images: string[];
    settings: any; // Define as 'any' or with a more specific type if you prefer
}

const SliderMain: React.FC<SliderMainProps> = ({ images, settings }) => {
    const [imageIndex, setImageIndex] = useState(0);

    // Default settings, which can be overridden by passed settings
    const defaultSettings = {
        dots: true,
        speed: 300,
        slidesToShow: 3,
        centerMode: true,
        centerPadding: "0px",
        autoplay: true,
        autoplaySpeed: 2000,
        infinite: true,
        beforeChange: (current: number, next: number) => setImageIndex(next),
    };

    // Combine default settings with any overrides provided through props
    const sliderSettings = { ...defaultSettings, ...settings };

    return (
        <div className="lower-section pt-[7%] pb-[7%]">
            <Slider {...sliderSettings}>
                {images.map((img, idx) => (
                    <div className={idx === imageIndex ? 'slide activeSlide' : 'slide'} key={idx}>
                        <div className="card-image flex flex-col items-center justify-center">
                            <img src={img} alt={`slide-${idx}`} className="rounded-[10px]" />
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    );
};

export default SliderMain;
